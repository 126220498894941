<template>
  <div class="exam-content">
    <div class="row">
      <div class="col-md-8">
        <div class="exam-wrap">
          <div class="__header">
            <h4>{{ quiz.title }}</h4>
            <p>TEST INSTRUCTION : CLICK OPTION (A, B, C, D)</p>
          </div>
          <div class="__body" v-if="isAlreadyAttended == false">
            <h5 v-if="isLoading == false">Question {{ questionIndex + 1 }}</h5>
            <p class="question" v-html="question.question"></p>
            <div class="answer-list" v-if="isLoading == false">
              <ul class="list-unstyled">
                <li
                  v-for="(answer, answerIndex) in question.options"
                  :key="answerIndex"
                >
                  <a
                    href="#"
                    @click.prevent="updateAnswer(answer.id, question.id)"
                    :class="answer.id == selectedAnswer ? 'active' : ''"
                    ><span v-if="answerIndex == 0">A</span
                    ><span v-if="answerIndex == 1">B</span
                    ><span v-if="answerIndex == 2">C</span
                    ><span v-if="answerIndex == 3">D</span></a
                  >
                  <p>{{ answer.option }}</p>
                </li>
              </ul>
            </div>
            <webinar-loader v-else></webinar-loader>

            <div class="button-wrap">
              <button
                class="cta-primary btn"
                v-if="questionIndex > 0"
                @click.prevent="previousQuestion(question.id)"
              >
                Previous
              </button>
              <button
                class="cta-primary btn"
                v-if="questionIndex < questions.length - 1"
                @click.prevent="nextQuestion(question.id)"
              >
                Next
              </button>
            </div>
          </div>
          <div class="__body" v-else>
            <p class="message-text">{{ message }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="isAlreadyAttended == false">
        <div class="exam-wrap">
          <div class="__header">
            <h5>
              <vue-countdown
                :time="duration * 60 * 1000"
                v-slot="{ hours, minutes, seconds }"
                @progress="endCountDown"
              >
                Time: {{ hours }} H : {{ minutes }} M : {{ seconds }} S
              </vue-countdown>
            </h5>
          </div>
          <div class="__body">
            <div class="inidicator-wrap">
              <ul class="list-unstyled">
                <li
                  v-for="(question, questionIndex) in questions"
                  :key="questionIndex"
                >
                  <a
                    :class="
                      attendedQuestion.includes(question.id) ? 'attended' : ''
                    "
                    href="javascript:void(0);"
                    >{{ questionIndex + 1 }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="indicator-info">
              <ul class="list-unstyled">
                <li>
                  Attended
                </li>
                <li>
                  Not Attended
                </li>
              </ul>
            </div>
            <div class="text-center mt-3">
              <button
                class="cta-primary btn m-auto"
                @click.prevent="submitExam(question.id)"
                :disabled="isSubmittingExam == true"
                ref="submitButtonClick"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuizService from "@/services/QuizService";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  name: "QuizPortal",
  components: { WebinarLoader, VueCountdown },
  created() {
    this.getQuestions();
  },
  data() {
    return {
      quiz: [],
      questions: [],
      questionIndex: 0,
      question: "",
      answers: [],
      selectedAnswer: "",
      selectedAnswerArray: [],
      attendedQuestion: [],
      isSubmittingExam: false,
      isAlreadyAttended: false,
      isLoading: true,
      message: "",
      quizId: this.$route.params.id,
      duration: 0
    };
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isNestMember"]);
      return this.$store.getters["privilege/isNestMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isNestMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  methods: {
    getQuestions: function() {
      this.isLoading = true;
      QuizService.getQuestions({
        quiz_id: this.quizId
      })
        .then(result => {
          this.isLoading = false;
          if (result.data.status == "success") {
            this.questions = result.data.questions;
            this.quiz = result.data.quiz;
            this.duration = this.quiz.duration;
            this.getQuestion();
          } else {
            this.isAlreadyAttended = true;
            this.message = result.data.message;
            this.quiz = result.data.quiz;
          }
        })
        .catch(error => {
          this.error = error;
        });
    },
    getQuestion: function() {
      if (this.questions.length > 0) {
        this.question = this.questions[this.questionIndex];
        let questionId = this.question.id;
        let selectedAnswer = this.selectedAnswerArray.find(
          selectedAnswer => selectedAnswer.question_id === questionId
        );
        if (selectedAnswer !== undefined) {
          this.selectedAnswer = selectedAnswer.answer_id;
        } else {
          this.selectedAnswer = "";
        }
      } else {
        this.question = "";
      }
    },
    updateAnswer(answerId, questionId) {
      this.selectedAnswer = answerId;
      this.attendedQuestion.push(questionId);
    },
    nextQuestion(questionId) {
      if (this.selectedAnswer != "") {
        this.storeAnswer(questionId);
      }
      this.questionIndex += 1;
      this.getQuestion();
    },
    previousQuestion(questionId) {
      if (this.selectedAnswer != "") {
        this.storeAnswer(questionId);
      }
      this.questionIndex -= 1;
      this.getQuestion();
    },
    storeAnswer: function(questionId) {
      const answer = {
        quiz_id: this.quizId,
        question_id: questionId,
        answer_id: this.selectedAnswer
      };
      for (var i = 0; i < this.selectedAnswerArray.length; i++) {
        var item = this.selectedAnswerArray[i];
        if (item.question_id == questionId) {
          this.selectedAnswerArray.splice(i, 1);
        }
      }
      this.selectedAnswerArray.push(answer);
      this.selectedAnswer = "";
    },
    submitExam: function(questionId) {
      this.isSubmittingExam = true;
      this.storeAnswer(questionId);
      this.answerError = "";
      QuizService.submitQuiz({
        quiz_id: this.quizId,
        result: this.selectedAnswerArray
      })
        .then(result => {
          this.isSubmittingExam = false;
          this.isAlreadyAttended = true;
          this.message = result.data.message;
        })
        .catch(error => {
          this.isSubmittingExam = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    endCountDown: function(data) {
      if (data.totalSeconds <= 1) {
        this.$refs.submitButtonClick.click();
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/style/exam-portal.scss";
</style>
